<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field
          v-model="search"
          placeholder="Search for a notification by name..."
          outlined
          clearable
          counter
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="notifications.records"
          :loading="isLoading"
          :search="search"
          transition="scale-transition"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                ><h4 class="text-md-h4 primary--text">
                  Notifications
                </h4></v-toolbar-title
              >

              <v-btn
                class="mt-2"
                color="primary"
                large
                @click="$router.push('/admin/notification/0/edit')"
              >
                <h1>New Notification</h1>
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
              <v-spacer />
              <download-excel
                :data="notifications.records"
                :fields="fields"
                worksheet="Notifications"
                name="NotificationsList.xls"
              >
                <v-btn color="#0a2752" large dark>
                  <h1>Download</h1>
                </v-btn>
              </download-excel>
            </v-toolbar>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <v-row>
              <h3>Created At:</h3>
              <h3 color="primary">{{ item.createdAt | getFormattedDate }}</h3>
            </v-row>
            <v-row>
              <h3 color="primary">Updated At:</h3>
              <h3 color="primary">{{ item.updatedAt | getFormattedDate }}</h3>
            </v-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              fab
              small
              dark
              class="mr-2"
              @click="editItem(item)"
              color="primary"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              dark
              class="mr-2"
              @click="deleteItem(item)"
              color="error"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      headers: [
        { text: "Description", value: "description" },

        // { text: "price", value: "price" },
        { text: "Date", value: "createdAt" },
        { text: "Actions", align: "right", sortable: false, value: "actions" },
      ],
      search: "",
      notifications: [],
      dialog: false,
      totalRecords: 0,
      isLoading: false,
      fields: {
        "Notification Description": "description",

        "Created At": "createdAt",
      },
    };
  },

  apollo: {
    notifications: {
      query: gql`
        query Notifications($limit: Int!, $skip: Int!, $query: JSON!) {
          notifications(limit: $limit, skip: $skip, query: $query) {
            records {
              id

              description

              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },

  methods: {
    editItem(item) {
      this.$router.push(`/admin/notification/${item.id}/edit`);
    },
    defaultProfileImage() {
      return defaultProfile;
    },
    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteNotification(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "Notification was successfully deleted",
                "success"
              );
              this.$router.go(this.$route.currentRoute);
            }
          } catch (e) {}
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
